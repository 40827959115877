import React, { useState, useReducer } from "react";
import emailjs from "emailjs-com";


const service = process.env.REACT_APP_EMAIL_JS_SERVICE;
const template = process.env.REACT_APP_EMAIL_JS_TEMPLATE;
const userId = process.env.REACT_APP_EMAIL_JS_USER;

const initialState = {
    name: "",
    email: "",
    message: "",
};

function reducer(state, action) {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "email":
            return { ...state, email: action.value };
        case "message":
            return { ...state, message: action.value };
        default:
            throw new Error();
    }
}



function ContactForm() {

    const [formState, dispatch] = useReducer(reducer, initialState);
    const { name, email, message } = formState;
    const [showFormErr, setShowFormErr] = useState(false);
    const [showFormOrSuccess, setShowFormOrSuccess] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setLoadingSpinner(true);
        const params = {
            ...formState,
        };

        if (name === "" || email === "" || message === "") {
            setShowFormErr(true);
            return;
        }

        emailjs.send(service, template, params, userId).then(
            ({ status }) => {
                if (status === 200) {
                    setShowFormOrSuccess(true);
                } else {
                    console.log(" Opps, something went wrong, please try again!");
                }
            },
            (err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                console.log(" Opps, something went wrong, please try again!");
            }
        );
    };


    return (

        <>
            {
                showFormOrSuccess ?

                    <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
                        < div className="container mx-auto px-4" >
                            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                                        <div className="flex-auto p-5 lg:p-10">
                                            <h3>    Thank you! Your message has been sent. Please allow up to 48 business hours for a response.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </section >
                    :
                    <form onSubmit={handleOnSubmit}>


                        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
                            <div className="container mx-auto px-4">
                                <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                                            <div className="flex-auto p-5 lg:p-10">

                                                <div className="relative w-full mb-3 mt-8">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="full-name"
                                                    >
                                                        Full Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Full Name"
                                                        id="full-name"
                                                        name="user_name"
                                                        value={name}
                                                        onChange={(e) =>
                                                            dispatch({ type: "name", value: e.target.value })
                                                        }
                                                        maxLength={40}
                                                        minLength={2}
                                                        required
                                                    />
                                                </div>

                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="email"
                                                    >
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Email"
                                                        id="email"
                                                        name="user_email"
                                                        value={email}
                                                        onChange={(e) =>
                                                            dispatch({ type: "email", value: e.target.value })
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="message"
                                                    >
                                                        Message
                                                    </label>
                                                    <textarea
                                                        rows="4"
                                                        cols="80"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                        placeholder="Type a message..."
                                                        id="message"
                                                        name="message"
                                                        value={message}
                                                        onChange={(e) =>
                                                            dispatch({ type: "message", value: e.target.value })
                                                        }
                                                        minLength={5}
                                                        required
                                                    />
                                                </div>
                                                {showFormErr ? (
                                                    <p className=" ">
                                                        Please fill in all three input boxes to send a message
                                                    </p>
                                                ) : null}
                                                <div className="text-center mt-6">
                                                    <button
                                                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="submit"
                                                    >
                                                        {loadingSpinner ? "..." : " Send Message"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
            }
        </>
    )
}

export default ContactForm